import { useDispatch, useSelector } from 'react-redux';
import { mentorActions } from '../../lib/redux/mentors/slice';
import { useTriggers } from '../navigation';
import useGoogleDrivePicker from '../api/mentors/useGoogleDrivePicker';
import useDropboxPicker from './useDropboxPicker';
import useDocumentTrain from '../api/mentors/useDocumentTrain';
import { toast } from 'react-hot-toast';

const google = window.google;

export default function useDatasetTypes() {
  const dispatch = useDispatch();
  const dataset = useSelector((state) => state.mentors.dataset);
  const mentor = useSelector((state) => state.mentors.edit);
  const { _handleUpdateModalStatus } = useTriggers();
  const { handleDocumentTrain } = useDocumentTrain();
  const { openChooser, appKey } = useDropboxPicker({
    autoShow: false,
  });
  const { handlePickerOpen, credentials } = useGoogleDrivePicker();

  const datasetTypes = [
    {
      type: 'Google Drive',
      img_url: '/images/drive.png',
      active: true, //!!credentials.developer_key,
      ...(credentials.developer_key
        ? {}
        : { tooltip: 'Credentials Not Loaded' }),
    },
    {
      type: 'Dropbox',
      img_url: '/images/dropbox.png',
      active: true, //!!appKey,
      ...(appKey ? {} : { tooltip: 'Credentials Not Loaded' }),
    },
    {
      type: 'YouTube',
      img_url: '/images/65048a233ee9c46785a13c87_youtube-copy.png',
      active: false,
    },
    {
      type: 'URL',
      img_url: '/images/65048a233ee9c46785a13c7a_link-copy.png',
      active: true,
    },
    {
      type: 'PDF',
      img_url: '/images/65048a233ee9c46785a13c74_inbox-copy.png',
      active: true,
    },
    {
      type: 'DOCX',
      img_url: '/images/65048a233ee9c46785a13c89_docs-copy.png',
      active: true,
    },
    {
      type: 'GitHub',
      img_url: '/images/65048a233ee9c46785a13c8d_github-copy.png',
      active: true,
    },
    {
      type: 'TEXT',
      img_url:
        '/images/65048a233ee9c46785a13cc2_align-left-copy_165048a233ee9c46785a13cc2_align-left-copy.png',
      active: true,
    },
    {
      type: 'Audio',
      img_url:
        '/images/65048a233ee9c46785a13ca3_audio-speaker-copy_165048a233ee9c46785a13ca3_audio-speaker-copy.png',
      active: false,
    },
    {
      type: 'Video',
      img_url: '/images/65048a233ee9c46785a13ca6_video-camera.png',
      active: false,
    },
    {
      type: 'Course',
      img_url:
        '/images/65048a233ee9c46785a13caa_book-copy_165048a233ee9c46785a13caa_book-copy.png',
      active: false,
    },
  ];

  const handleSuccess = async (files) => {
    const trainPayload = {
      pathway: mentor?.mentor?.name,
      url: files.map((file) => file.link).join(','),
      type: 'dropbox',
      access: 'private',
      dropbox_auth_data: files
    };
    handleDocumentTrain(trainPayload).then((data) =>
      toast.success('Document has been queued for training!')
    );
  };

  const handleSelect = (type) => {
    dispatch(mentorActions.datasetUpdated({ ...dataset, type: type }));
    if (type === 'Google Drive') {
      handlePickerOpen();
      return;
    }
    if (type === 'Dropbox') {
      openChooser(handleSuccess);
      return;
    }
    _handleUpdateModalStatus('dataset-url-resource-modal', true);
  };

  const getInActiveStatus = (item) => {
    if (!item.active) {
      return 'inactive';
    }
    return '';
  };

  return { datasetTypes, getInActiveStatus, handleSelect };
}
