import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function useEditMentorTabs() {
  const edit = useSelector((state) => state.mentors.edit);
  const [tab, setTab] = useState(edit?.activeTab || 'settings');
  const editMentor = useSelector((state) => state.mentors.edit.mentor);
  const { t } = useTranslation();
  const allTabs = [
    { key: 'settings', title: t('Settings') },
    { key: 'llm', title: t('LLM') },
    { key: 'prompts', title: t('Prompts') },
    { key: 'tools', title: t('Tools') },
    { key: 'safety', title: t('Safety') },
    { key: 'history', title: t('History') },
    { key: 'datasets', title: t('Datasets') },
    { key: 'embed', title: t('Embed') },
  ];

  const disabledTabsStr = process.env.REACT_APP_IBL_MENTOR_DISABLED_TABS || '';
  const disabledTabs =
    disabledTabsStr?.split(',')?.map((tab) => tab?.trim().toLowerCase()) || [];

  const tabs = allTabs.filter((item) => !disabledTabs?.includes(item.key));

  return { tab, setTab, tabs };
}
